import React from "react"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import "./theteam.css"

const TheTeam = () => {
    const data = useStaticQuery(
        graphql`
          query {
            teamImages: allFile(filter: {relativeDirectory: {eq: "team"}}) {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 200, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  name
                }
              }
            }
          }
        `
    )

    const teamMembers = [
        { name: "Tang Pak Hong, James", title: "Managing Director" },
        { name: "Tang Yiting, Vanessa", title: "Managing Director" },
        { name: "Tang Wan Jun, Valerie", title: "Business Development Director" },
        { name: "Lim Li Sing, Janet", title: "Sales and Operations Director" },
        { name: "Tang Jing Yuan, Ivan", title: "Operations Manager" },
        { name: "Pamela Gonzaga", title: "Training Manager", email: "pamela@aia.edu.sg", contact: "+65 9004 6756" },
        { name: "Mursyidah Md Saidin", title: "Training Coordinator", email: "mursyidah@aia.edu.sg", contact: "+65 8660 3853" },
        { name: "Kee Kay Loke", title: "Lead Trainer – BIM" },
        { name: "Zulkifli Mohd Ain", title: "Head Trainer – CAD" },
        { name: "Muhd Azman Ahmad Said", title: "BIM Manager" },
        { name: "Muhammad Nuriman Zulkifli", title: "Trainer" },
        { name: "Mohd Haziq Mohd Walad", title: "Trainer" },
        { name: "Muhd Azree Abdul Karim", title: "Technical Specialist" },
        { name: "Muhammad Danish Arfan Norzahid", title: "Technical Specialist" },
        { name: "Tristan Randall Anthony", title: "Junior Trainer" }
    ];

    const findImage = (name) => {
        const image = data.teamImages.edges.find(
            edge => edge.node.name === name.toLowerCase().replace(/,| /g, '')
        )
        return image ? image.node.childImageSharp.fluid : null
    }

    return (
        <>
            <SEO
                title="BIM & CAD Training Center | Ace Industrial Academy"
                description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
            />
            <TitleBannerEnquiry
                title="Meet The Team"
                description="Ace Industrial Academy Organizational Structure"
                withOnlyDescription
                hasPlainBanner
            />

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container fluid className="pt-4">
                    <h3 className="trainingContent mt-3 text-center">Management</h3><br />
                    <div className="team-grid management-grid">
                        <Row className="justify-content-center">
                            {teamMembers.slice(0, 5).map((member, index) => (
                                <Col key={index} xs="12" sm="6" md="4" lg="2" className="mb-4">
                                    <div className="team-card management-card">
                                        <Img
                                            fluid={findImage(member.name)}
                                            alt={member.name}
                                            className="team-image mb-3"
                                        />
                                        <h5>{member.name}</h5>
                                        <p className="text-muted">{member.title}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3 text-center">Training Support</h3><br />
                    <div className="team-grid training-support-grid">
                        <Row className="justify-content-center">
                            {teamMembers.slice(5, 7).map((member, index) => (
                                <Col key={index} xs="12" sm="6" md="4" lg="3" className="mb-4">
                                    <div className="team-card training-support-card">
                                        <h5>{member.name}</h5>
                                        <p className="text-muted">{member.title}</p>
                                        {member.email && <p className="text-muted email"><a href={`mailto:${member.email}`}><em>{member.email}</em></a></p>}
                                        {member.contact && <p className="text-muted contact"><em>{member.contact}</em></p>}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h3 className="trainingContent mt-3 text-center">Training / Technical Team</h3><br />
                    <div className="team-grid">
                        <Row className="justify-content-center">
                            {teamMembers.slice(7, 11).map((member, index) => (
                                <Col key={index} xs="12" sm="6" md="4" lg="3" className="mb-4">
                                    <div className="team-card">
                                        <h5>{member.name}</h5>
                                        <p className="text-muted">{member.title}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <Row className="justify-content-center">
                            {teamMembers.slice(11).map((member, index) => (
                                <Col key={index} xs="12" sm="6" md="4" lg="3" className="mb-4">
                                    <div className="team-card">
                                        <h5>{member.name}</h5>
                                        <p className="text-muted">{member.title}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container><br /><br />
            </Container>
        </>
    )
}

export default TheTeam
